import { useEffect, memo } from 'react';

type DeferOCProps = {
  html: string;
};

// defer script execution by adding the tags after the first "tick"
const DeferOC = ({ html }: DeferOCProps) => {
  useEffect(() => {
    const tag = /\/\/.*\.js/gi.exec(html || '')?.[0];
    if (tag) {
      const script = document.createElement('script');

      script.src = tag;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [html]);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html.replaceAll(
          /<script\s+(?:defer\s+)?src=.*?<\/script>/gi,
          '',
        ),
      }}
    />
  );
};

export default memo(DeferOC);
